import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import YouTube, { YouTubeProps } from "react-youtube"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons"

import { Interview } from "../types"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OnScreen from "../components/OnScreen"
import Button from "../components/Button"

interface Props {
  data: {
    contentfulInterview: Interview
  }
}

const website_url = "https://mooremomentum.com"
const InterviewTemplate: FunctionComponent<Props> = ({ data }) => {
  const youtubeParser = (url: string) => {
    // var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    // var match = url.match(regExp);
    // return (match && match[7].length == 11) ? match[7] : false;
    var video_id = url.split("v=")[1]
    var ampersandPosition = video_id.indexOf("&")
    if (ampersandPosition != -1) {
      video_id = video_id.substring(0, ampersandPosition)
    }
    return video_id
  }

  const onPlayerReady: YouTubeProps["onReady"] = event => {
    // access to player in all event handlers via event.target
    event.target.unMute()
    // event.target.setVolume(100);
    // event.target.playVideo();
  }
  const onEnd: YouTubeProps["onEnd"] = event => {
    // access to player in all event handlers via event.target
    event.target.playVideo()
  }

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
      fs: 0,
      showinfo: 0,
    },
  }

  return (
    <Layout>
      <SEO
        description={data.contentfulInterview.blurb}
        title={data.contentfulInterview.title}
        link={`https://mooremomentum.com/interview-of-will-moore/${data.contentfulInterview.slug}/`}
      />
      <div className="max-w-6xl mt-8 mx-auto px-4 overflow-x-hidden mb-8">
        <h1 className="font-Orbitron mb-4 text-center text-2xl sm:text-3xl lg:text-4xl">
          {data.contentfulInterview.title}
        </h1>
        <h2 className="text-center mb-32">
          {data.contentfulInterview.publishedDate}
        </h2>

        <div className="max-w-full">
          <OnScreen
            className="px-4 text-center md:text-left"
            classToAdd="slide-in-right"
          >
            <div className="bg-blue flex flex-col justify-center items-center max-w-6xl mx-auto -mt-24 p-6 relative rounded-2xl shadow-xl z-20">
              <h2 className="font-Orbitron text-white text-xl text-center md:text-2xl mb-8">
                {data.contentfulInterview.title}
              </h2>

              {data.contentfulInterview.mediaUrl !== null && (
                <YouTube
                  videoId={youtubeParser(data.contentfulInterview.mediaUrl)}
                  className="video-iframe h-full md:h-96 w-full"
                  opts={opts}
                  onReady={onPlayerReady}
                  onEnd={onEnd}
                />
              )}
              {data.contentfulInterview.mediaUrl === null &&
                data.contentfulInterview.link && (
                  <a
                    href={data.contentfulInterview.link}
                    rel="noreferrer noopener"
                    target="_blank"
                    className="w-1/2 text-center"
                  >
                    <GatsbyImage
                      alt={data.contentfulInterview.coverImage.description}
                      className="mb-8"
                      image={
                        data.contentfulInterview.coverImage.gatsbyImageData
                      }
                    />

                    {/* <div>
                                     <h3 className="font-Orbitron text-center sm:text-lg">
                                       {data.contentfulInterview.title}
                                      </h3>
                                    </div> */}
                  </a>
                )}

              {data.contentfulInterview.mediaUrl === null &&
                data.contentfulInterview.link === null &&
                data.contentfulInterview.appleUrl && (
                  <a
                    href={data.contentfulInterview.appleUrl}
                    rel="noreferrer noopener"
                    target="_blank"
                    className="w-1/2 text-center"
                  >
                    <GatsbyImage
                      alt={data.contentfulInterview.coverImage.description}
                      className="mb-8"
                      image={
                        data.contentfulInterview.coverImage.gatsbyImageData
                      }
                    />
                  </a>
                )}

              <p className="mb-4 mt-8 text-bold text-sm text-white text-center">
                {data.contentfulInterview.blurb}
              </p>
            </div>
          </OnScreen>
        </div>
        {data.contentfulInterview.content && (
          <div className="py-8 space-y-4">
            {/* @ts-ignore */}
            {renderRichText(data.contentfulInterview.content, {
              renderMark: {
                [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
                [MARKS.ITALIC]: text => <em>{text}</em>,
              },

              renderNode: {
                [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
                [INLINES.HYPERLINK]: ({ data }, children) => (
                  <a
                    href={data.uri}
                    className="underline"
                    target={`${
                      data.uri.startsWith(website_url) ? "_self" : "_blank"
                    }`}
                    rel={`${
                      data.uri.startsWith(website_url)
                        ? ""
                        : "noopener noreferrer"
                    }`}
                  >
                    {children}
                  </a>
                ),
                [BLOCKS.HEADING_1]: (node, children) => (
                  <h1 className="font-Orbitron text-2xl">{children}</h1>
                ),
                [BLOCKS.HEADING_2]: (node, children) => (
                  <h2 className="font-Orbitron text-xl">{children}</h2>
                ),
                [BLOCKS.HEADING_3]: (node, children) => (
                  <h3 className="font-Orbitron text-lg">{children}</h3>
                ),
              },
            })}
            {/* <Button href="https://anchor.fm/5corelife/message" link="GATSBY">
                        Send Voice Message @Anchor
                    </Button> */}
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    contentfulInterview(slug: { eq: $slug }) {
      content {
        raw
      }
      coverImage {
        description
        gatsbyImageData(layout: CONSTRAINED)
      }
      publishedDate(formatString: "MMM D, YYYY")
      slug
      title
      link
      appleUrl
      id
      blurb
      mediaUrl
    }
  }
`

export default InterviewTemplate
